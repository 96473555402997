import React from "react";
import "../assets/css/bootstrap.css";
import "../assets/css/fontawesome.css";
import "../assets/css/swain.css";
import "../assets/css/metisMenu.css";
import "../assets/css/onoffcanvas.css";
import "../assets/css/animate.css";
import { Container } from "shards-react";
import "fabric-webpack";
import DesignCanvas from "../components/layout/swain_mat_designer/DesignCanvas";
import { connect } from "react-redux";
import Text from "../components/Text";
import CurvedText from "../components/CurvedText";
import Rect from "../components/Rect";
import Image from "../components/Image";
import Template1 from "../components/Templates/Template1";
import Template2 from "../components/Templates/Template2";
import Template3 from "../components/Templates/Template3";
import Template4 from "../components/Templates/Template4";
import Template5 from "../components/Templates/Template5";
import Template6 from "../components/Templates/Template6";
import Template7 from "../components/Templates/Template7";
import Template8 from "../components/Templates/Template8";
import Template9 from "../components/Templates/Template9";
import Template10 from "../components/Templates/Template10";
import Template11 from "../components/Templates/Template11";
import Template12 from "../components/Templates/Template12";
import Template13 from "../components/Templates/Template13";
import Template14 from "../components/Templates/Template14";
import Template15 from "../components/Templates/Template15";
import { bindActionCreators } from "redux";
import OptionSettingDialogue from "../components/layout/swain_mat_designer/Option_Settting_Dialogue";
import Sidebar from "../components/layout/swain_mat_designer/Sidebar";
import ExtraMenu from "../components/layout/swain_mat_designer/ExtraMenu";
import SidebarItems from "../components/layout/swain_mat_designer/SidebarItems";
import $ from "jquery";
import axios from "axios";
import Alert from "../components/Alert";
import {SwainColorHex} from "../components/layout/ColorCodeHelper";

class Dashboard extends React.Component {
  state = {
    showAlert: false,
    firstAlert: true, // added to show tooltip only once even if object is removed
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== undefined) {
      this.props.setSaveDesignID(id);
    }

    $(".material-color-pane").css({ visibility: "hidden" });
    $(".text-color-pane").css({ display: "none" });
  }

  handleCloseAlert = () => {
    this.setState({ showAlert: false });
  };

  updateCanvasState = () => {
    let tempConfig = this.props._config;
    if (tempConfig.undoStatus === false && tempConfig.redoStatus === false) {
      let jsonData = this.props.canvas.toJSON();
      let canvasAsJson = JSON.stringify(jsonData);
      if (tempConfig.currentStateIndex < tempConfig.canvasState.length - 1) {
        let indexToBeInserted = tempConfig.currentStateIndex + 1;
        tempConfig.canvasState[indexToBeInserted] = canvasAsJson;

        let numberOfElementsToRetain = indexToBeInserted + 1;
        tempConfig.canvasState = tempConfig.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        tempConfig.canvasState.push(canvasAsJson);
      }

      tempConfig.currentStateIndex = tempConfig.canvasState.length - 1;

      if (
        tempConfig.currentStateIndex === tempConfig.canvasState.length - 1 &&
        tempConfig.currentStateIndex !== -1
      ) {
        tempConfig.redoButton = "disabled";
      }
    }
    this.props.setCanvasConfig(tempConfig);
  };

  showEditArtWorkDialogue = () => {
    $(".material-color-pane").css({ visibility: "hidden" });
  };

  setArtWorkColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    let activeGroup = this.props.canvas.getActiveGroup();
    console.log(activeGroup);
    if(activeObject){
      activeObject.set("fill", color);
      this.props.canvas.renderAll();
    }
    if(activeGroup){
      let objects = activeGroup._objects;
      objects.forEach(item=>{
        if(item.oldFill){
          item.set({fill: color});
        }else {
          item.set({fill: color,oldFill:item.fill});
        }
      })
      this.props.canvas.renderAll();
    }
    this.updateCanvasState();
  };

  deleteSelectedMaterial = () => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject) {
      this.props.canvas.remove(activeObject);
      this.props.removeRect(activeObject);
    }
    let activeGroup = this.props.canvas.getActiveGroup();
    console.log('group ',activeGroup);
    if(activeGroup){
      let objects = activeGroup._objects;
      this.props.canvas.deactivateAll().renderAll();
      objects.forEach(item=>{
        this.props.removeRect(item);
        this.props.canvas.remove(item);
      })
    }
    this.updateCanvasState();
    this.clearSelection();
  };
  clearSelection=()=>{
    $('.deleteBtn').css('visibility', 'hidden')
    $('.material-color-pane').css({ display: 'none' })
    $('.text-color-pane').css({ display: 'none' })

    $('#bold').removeClass('active')
    $('#italic').removeClass('active')
    $('#underline').removeClass('active')
    $('#capital').removeClass('active')
    $('#left-align').removeClass('active')
    $('#center-align').removeClass('active')
    $('#right-align').removeClass('active')
    $('.slct-font-size').val($('.slct-font-size option:first').val())
    $('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $('#line_ht_get').val(1)
    $('#line_ht_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#line_ht_set').val(1)
    $('#spacing_get').val(1)
    $('#spacing_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#spacing_set').val(1)
    $('#text-color-bind').css({ background: 'black' })
    $('#size_get').val(0)
    $('#size_get').css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    })
    $('#size_set').val(0)
    $('#outline-color-bind').css({ background: 'black' })
    $('#selected_text').val('sampletext')
    $('.add-text-btn').text('Add Text')
  }

  reStoreSelectedMaterial = () => {
    let activeObject = this.props.canvas._activeObject;
    let activeGroup = this.props.canvas.getActiveGroup();
    console.log('restore',activeGroup);
    if(activeObject){
      activeObject.set("fill", this.props.selectedMaterialColor);
      this.props.canvas.renderAll();
    }
    if(activeGroup){
      let objects = activeGroup._objects;
      objects.forEach(item=>{
        if(item.oldFill){
          let fill = item.oldFill;
          delete item.oldFill;
          item.set({fill: fill});
        }
      })
      this.props.canvas.renderAll();
    }
    this.updateCanvasState();
  };

  clearCanvas = (e) => {
    e.preventDefault();
    this.props.canvas.clear();
    window.scrollTo(0, 0);
    this.updateCanvasState();
    this.props.clearCanvas();
    clearTimeout(this.popupTimer);
    this.setState({ showAlert: false, firstAlert: true });
  };

  tooltipHandler = (alert, first = false) => {
    if (alert) {
      this.setState({ showAlert: true, firstAlert: false });
      this.popupTimer = setTimeout(() => {
        this.setState({ showAlert: false });
      }, 5000);
    }
    if (alert === false) {
      this.setState({ showAlert: false });
    }
  };
  clearSelection = () => {
    this.props.canvas.deactivateAll().renderAll();
    $(".deleteBtn").css("visibility", "hidden");
    $(".material-color-pane").css({ display: "none" });
    $(".text-color-pane").css({ display: "none" });

    $("#bold").removeClass("active");
    $("#italic").removeClass("active");
    $("#underline").removeClass("active");
    $("#capital").removeClass("active");
    $("#left-align").removeClass("active");
    $("#center-align").removeClass("active");
    $("#right-align").removeClass("active");
    //$('.slct-font-size').val($('.slct-font-size option:first').val())
    //$('.slct-font-fam').val($('.slct-font-fam option:first').val())
    $("#line_ht_get").val(1);
    $("#line_ht_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#line_ht_set").val(1);
    $("#spacing_get").val(1);
    $("#spacing_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#spacing_set").val(1);
    $("#text-color-bind").css({ background: "black" });
    $("#size_get").val(0);
    $("#size_get").css({
      background: `linear-gradient(to right, #1baa92 0%, #1baa92 5%, #fff 5%, #fff 100%)`,
    });
    $("#size_set").val(0);
    $("#outline-color-bind").css({ background: "black" });
    $("#selected_text").val("sampletext");
    $(".add-text-btn").text("Add Text");
  };
  reduceDesignColor=(colorCode)=>{
    if(colorCode.indexOf('rgb') >= 0){
      let a = colorCode.split("(")[1].split(")")[0];
      a = a.split(",");
      let b = a.map(function(x){                      //For each array element
        x = parseInt(x).toString(16);      //Convert to a base16 string
        return (x.length===1) ? "0"+x : x; //Add zero if we get only one character
      });
      colorCode = "#"+b.join("");
    }
    let colorName  = colorCode,
        colorNames = Object.keys(SwainColorHex);
    colorNames.forEach(color=>{
      if(SwainColorHex[color] === colorName){
        colorName = color;
      }
    })
    return colorName;
  }
  onOutOfCanvas = (activeObject) => {
    console.log("called");
    let out = false;
    activeObject.setCoords();
    let cords = activeObject.oCoords;
    let originalHeight = this.props.canvas.height / this.props.canvas.getZoom();
    let originalWidth = this.props.canvas.width / this.props.canvas.getZoom();
    //if is out from top
    if (cords.tl.y < 0 && cords.tr.y < 0 && cords.br.y < 5 && cords.bl.y < 5) {
      out = true;
    }
    //if is out from left
    else if (cords.tl.x < 5 && cords.tr.x < 5 && cords.br.x < 5 && cords.bl.x < 5) {
      out = true;
    }
    //if is out from bottom
    else if (cords.tl.x > originalWidth && cords.bl.x > originalWidth) {
      out = true;
    }
    //if is out from right
    else if (cords.tr.y > originalHeight && cords.tl.y > originalHeight) {
      out = true;

    }
    return out;
  }

  sendQuote = ({ fullName: name, email, phone, zipcode, subject, message }) => {
    this.clearSelection();
    const matData = [{}];
    const unit = this.props.swain_config.length_unit === "FEET" ? "ft" : "m";
    const unit1 = this.props.swain_config.length_unit === "FEET" ? 3.28 : 1;
    const canvas_grid = this.props.canvas_grid;
    matData[0].matSize =
      this.props.swain_config.width +
      unit +
      " x " +
      this.props.swain_config.height+unit;

    const rects = {};
    const allrects = this.props.canvas.getObjects('rect');

    console.log(allrects)
    for (let i = 0; i < allrects.length; i++) {
      //setting all horizontals as vertivals
      let size;
      if(this.onOutOfCanvas(allrects[i])){
        console.log('is out of canvas area');
        continue;
      }
      console.log('index=',i)
      if (
          allrects[i].width / canvas_grid / unit1 >
          allrects[i].height / canvas_grid / unit1
      ) {
        size =
          parseInt(allrects[i].height / canvas_grid / unit1) +
          " x " +
          parseInt(allrects[i].width / canvas_grid / unit1);
      } else {
        size =
          parseInt(allrects[i].width / canvas_grid / unit1) +
          " x " +
          parseInt(allrects[i].height / canvas_grid / unit1);
      }
      let color = this.reduceDesignColor(allrects[i].fill.toString());
      if (rects[size + "##" + color]) {
        rects[size + "##" + color].count += 1;
      } else {
        rects[size + "##" + color] = {
          count: 1,
        };
      }
    }
    const colors = [];
    Object.keys(rects).forEach((key, i) => {
      const strs = key.split("##");
      colors[i] = {
        count: rects[key].count,
        color: strs[1],
        size: strs[0],
      };
    });

    matData[0].colors = colors;
    matData[0].logos = this.props.uploadedLogos;
    matData[0].svg = this.props.canvas.toSVG();
    matData[0].png = this.props.canvas.toDataURL();

    matData[0].text = this.props.text_style ? this.props.text_style.text : "";
    matData[0].font = this.props.text_style
      ? this.props.text_style.fontFamily
      : "";
    matData[0].fontColor = this.props.fontColor;

    const data = {
      name,
      email,
      phone,
      zipcode,
      subject,
      message,
      matData,
    };

    axios
      .post("/api/quote", data)
      .then((res) => {
        alert("Successfully saved!");
      })
      .catch((res) => {
        alert("An unexpected error occurred. Please contact Admin");
      });
  };



  setActiveTab = (id) => {
    $("#text-custom").css("display", "none");
    $("#text-rgb").css("display", "none");
    $("#text-cmyk").css("display", "none");
    $(`#${id}`).css("display", "block");
    $(".custom-colors .active").removeClass("active");
    $(`.${id}-color`).addClass("active");
  };

  setTextColor = (color) => {
    let activeObject = this.props.canvas._activeObject;
    if (activeObject == null) return;

    if (this.props.textColorOption === "fill") {
      activeObject.set("fill", color);
    } else {
      activeObject.set("stroke", color);
    }
    this.props.canvas.renderAll();
    this.updateCanvasState();
    this.props.setFontColorOption(color);
  };

  emptyClick = () => {
    if (window.innerWidth < 768) {
      document.getElementById('second-custom-right').style.display = 'none'
      document.getElementById("mySidenav1").style.width = "0";
      document.getElementById("mySidenav2").style.width = "0";
      document.getElementById("mySidenav3").style.width = "0";
      document.getElementById("mySidenav4").style.width = "0";
      document.getElementById("mySidenav5").style.width = "0";
    }
  }

  render() {
    const components = this.props.components;
    const swain_config = this.props.swain_config;
    if (!swain_config.option_setting) {
      return <OptionSettingDialogue />;
    }
    const unit = this.props.swain_config.length_unit === "FEET" ? "ft" : "m";
    const templateMessage = "TIP: Click to select the Template, then drag it to the Grid."
    const matMessage = "TIP: Click to select the Mat, then drag it to the Grid."
    return (
      <>
        <span id="tooltiptemplate" style={{
          visibility: 'hidden',
          width: 'fit-content',
          backgroundColor: 'grey',
          color: '#fff',
          textAlign: 'center',
          padding: '5px 5px',
          borderRadius: '6px',
          top: '45px',
          /* Position the tooltip text - see examples below! */
          position: 'absolute',
          zIndex: '9999',
          overflow: 'visible',
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',

        }}>{templateMessage}</span>
        <span id="tooltipmat" style={{
          visibility: 'hidden',
          width: 'fit-content',
          backgroundColor: 'grey',
          color: '#fff',
          textAlign: 'center',
          padding: '5px 5px',
          borderRadius: '6px',
          top: '45px',
          /* Position the tooltip text - see examples below! */
          position: 'absolute',
          zIndex: '9999',
          overflow: 'visible',
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 'normal',

        }}>{matMessage}</span>
        <div id="second-custom-right" onClick={this.emptyClick} />
        <Sidebar />
        <div className="col-md-4 col-sm-4 text-center pad0 leftpanel">
          <SidebarItems />
        </div>
        <div id="content">
          <div className="outer">
            <div className="inner bg-light lter">
              <ExtraMenu />
              <div className="row ml0 mar" style={{ background: "#ebf6f8" }}>
                <div className="text-center main-content custom-main-content">
                  <div className="main-edit-content">
                    <p className="matsize">
                          <span>
                            MAT SIZE : {swain_config.width}
                            {unit} x {swain_config.height}
                            {unit}
                          </span>
                    </p>
                    <div className="addcontdiv">
                      <div className="inneraddcontdiv">
                        <Container
                            fluid
                            className="main-content-container px-4 scroll-pad"
                        >
                          <DesignCanvas
                              //showAlert={this.state.showAlert}
                              tooltipHandler={this.tooltipHandler}
                          >
                            {components.map((component, idx) => {
                              switch (component.type) {
                                case "rectangle":
                                  return <Rect key={idx} />;
                                case "image":
                                  return <Image key={idx} />;
                                case "text":
                                  return <Text key={idx} />;
                                case "template1":
                                  return <Template1 key={idx} />;
                                case "template2":
                                  return <Template2 key={idx} />;
                                case "template3":
                                  return <Template3 key={idx} />;
                                case "template4":
                                  return <Template4 key={idx} />;
                                case "template5":
                                  return <Template5 key={idx} />;
                                case "template6":
                                  return <Template6 key={idx} />;
                                case "template7":
                                  return <Template7 key={idx} />;
                                case "template8":
                                  return <Template8 key={idx} />;
                                case "template9":
                                  return <Template9 key={idx} />;
                                case "template10":
                                  return <Template10 key={idx} />;
                                case "template11":
                                  return <Template11 key={idx} />;
                                case "template12":
                                  return <Template12 key={idx} />;
                                case "template13":
                                  return <Template13 key={idx} />;
                                case "template14":
                                  return <Template14 key={idx} />;
                                case "template15":
                                  return <Template15 key={idx} />;
                                default:
                                  return <CurvedText key={idx} />;
                              }
                            })}
                          </DesignCanvas>
                          {this.state.showAlert && !this.state.firstAlert && (
                              <Alert
                                  onClick={this.handleCloseAlert}
                                  isOpen={true}
                              />
                          )}
                        </Container>
                      </div>
                    </div>
                    <div id="drag_image" />
                    <div id="drag_template" />
                    <div id="preview_image" />
                  </div>
                  {/*<div className="getquote">*/}
                  {/*  <div className="inline-btns">*/}
                  {/*    <p>*/}
                  {/*      <QuoteModal sendQuote={this.sendQuote} />*/}
                  {/*      <span className="cgrid">*/}
                  {/*        <a href="#" onClick={this.clearCanvas}>*/}
                  {/*          <img*/}
                  {/*            src={require("./../assets/img/del.png")}*/}
                  {/*            alt="Delete"*/}
                  {/*          />{" "}*/}
                  {/*          Clear Grid*/}
                  {/*        </a>*/}
                  {/*      </span>*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                {/*<div className="col-md-1 col-sm-1 text-center pad0 h7 toolbar">*/}

                {/*  <Toolbar />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const setSaveDesignID = (id) => {
  return {
    type: "SET_SAVED_ID",
    id: id,
  };
};

const setCanvasConfig = (_config) => {
  return {
    type: "SET_CONFIG",
    _config,
  };
};

const clearCanvas = () => {
  return {
    type: "CLAER_CANVAS",
    isCanvasCleared: true,
  };
};

const removeRect = (data) => {
  return {
    type: "REMOVE_RECT",
    data,
  };
};

const setFontColorOption = (option) => {
  return {
    type: "SET_FONT_COLOR_OPTION",
    option,
  };
};

const mapStateToProps = (state) => {
  return {
    _config: state._config,
    components: [...state.components],
    components_type: [...state.components_type],
    swain_config: state.swain_config,
    canvas: state.canvas,
    selectedMaterialColor: state.selectedMaterialColor,
    textColorOption: state.textColorOption,
    rects: state.rects,
    text_style: state.text_style,
    fontColor: state.fontColor,
    uploadedLogos: state.uploadedLogos,
    canvas_grid: state.canvas_grid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSaveDesignID,
      setCanvasConfig,
      clearCanvas,
      removeRect,
      setFontColorOption,
    },
    dispatch
  );
};

const Swain_Designer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Swain_Designer;
